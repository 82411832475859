import * as _ from 'lodash'
import { FIELDS_ROLES } from '../constants/roles'
import { getFields } from './submit-utils'
import { CRM_TYPES } from '../constants/crm-types-tags'
import { URL_REGEX } from '../editor-app/core/preset/fields/general-fields/definitions/general-url'
import { EMAIL_REGEX } from '../editor-app/core/preset/fields/recommended-fields/definitions/email'

export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') //eslint-disable-line no-useless-escape

export const innerText = str => _.trim(str.replace(/\s*<[^>]*>\s*/gm, ''))

export const getFieldType = field => {
  return field.type.split('.')[1]
}

export const replaceMessageInnerText = (message, messageText: string, replaceFunc: (string) => string): void => {
  message.html = replaceFunc(messageText)
}

const isFieldType = (fieldType: string) => field => field.type === `$w.${fieldType}`

export const isUploadButton = isFieldType('UploadButton')
export const isCaptchaField = isFieldType('Captcha')
export const isRadioGroup = isFieldType('RadioButtonGroup')
export const isCheckbox = isFieldType('Checkbox')
export const isNumber = field => isFieldType('TextInput')(field) && field.inputType === 'number'

export const toMiliseconds = sec => sec * 1000

export const addContactsValidation = $w => {
  const fields = getFields({ $w, roles: FIELDS_ROLES })
  const maxLengthValidation = (field, crmType) => {
    const crmMaxLength = _.get(CRM_MAX_LENGTH, crmType)
    if (crmMaxLength) {
      const fieldMaxLength = _.isNumber(field.maxLength) ? field.maxLength : Infinity
      field.maxLength = Math.min(fieldMaxLength, crmMaxLength)
    }
  }
  const pattrenValidation = (field, crmType) => {
    if (CRM_PATTERNS[crmType]) {
      field.pattern = CRM_PATTERNS[crmType]
    }
  }

  fields.forEach(field => {
    const crmType = _.get(field, 'connectionConfig.crmType')
    maxLengthValidation(field, crmType)
    pattrenValidation(field, crmType)
  })
}

//https://github.com/wix-private/crm/blob/master/user-activity-domain/src/main/scala/com/wixpress/useractivity/entities/ContactUpdate.scala
export const CRM_MAX_LENGTH = {
  [CRM_TYPES.FIRST_NAME]: 100,
  [CRM_TYPES.ADDRESS]: 250,
  [CRM_TYPES.EMAIL]: 250,
  [CRM_TYPES.COMPANY]: 100,
  [CRM_TYPES.LAST_NAME]: 100,
  [CRM_TYPES.PHONE]: 50,
}

export const CRM_PATTERNS = {
  [CRM_TYPES.EMAIL]: EMAIL_REGEX,
  [CRM_TYPES.WEBSITE]: URL_REGEX,
}
